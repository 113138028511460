<template>
  <base-layout-two :page-title="user.fullname" :page-default-back-link="`/users/${user.id}/activities/${activity.id}`">

    <!-- Header -->
    <div class="padding-8">
      <base-card :title="activityTitle">
        <v-form @submit="updateActivity">
          <!-- Date -->
          <base-input label-text="Date *">
            <v-field name="datetime" v-slot="{ field }" v-model="activity.datetime" :rules="requiredString">
              <ion-input name="date" v-bind="field" type="datetime-local" :max="now">
              </ion-input>
            </v-field>
          </base-input>
          <v-error-message name="datetime" class="error-message">
          </v-error-message>

          <!-- Option 1 -->
          <base-input label-text="Formula or Breastmilk" v-if="showOption1">
            <v-field name="option_1" v-slot="{ field }" v-model="activity.option_1" :rules="requiredString">
              <select v-bind="field" class="regular-select">
                <option value="formula">Formula</option>
                <option value="breastmilk">Breastmilk</option>
              </select>
            </v-field>
          </base-input>
          <v-error-message name="option_1" class="error-message">
          </v-error-message>

          <!-- Measurement 1 -->
          <base-input :label-text="`Amount (${activity.units_1})*`" v-if="showMeasurement1">
            <v-field name="measurement_1" v-slot="{ field }" v-model="activity.measurement_1"
              :rules="(activity.type === 'baby_feeding') ? null : requiredNumber">
              <ion-input name="measurement_1" v-bind="field" type="number">
              </ion-input>
            </v-field>
          </base-input>

          <v-error-message name="measurement_1" class="error-message">
          </v-error-message>

          <!-- Units 1 -->
          <base-input label-text="Units *" v-if="showUnits1">
            <v-field name="units_1" v-slot="{ field }" v-model="activity.units_1" :rules="requiredString">
              <select v-bind="field" class="regular-select" disabled>
                <option value="ml">ml</option>
              </select>
            </v-field>
          </base-input>
          <v-error-message name="units_1" class="error-message">
          </v-error-message>

          <base-input label-text="Notes">
            <v-field name="notes" v-slot="{ field }" v-model="activity.notes">
              <ion-textarea name="notes" :auto-grow="true" rows="1" v-bind="field" placeholder="" autocapitalize
                autocorrect></ion-textarea>
            </v-field>
          </base-input>
          <v-error-message name="notes" class="error-message"></v-error-message>

          <ion-button type="submit" expand="block" :disabled="!canEdit">Save Changes</ion-button>
          <p class="small ion-text-center" v-if="!canEdit"><i>Editing is currently only available for Feedings and
              Diapering.</i></p>
        </v-form>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import { IonInput, IonButton, IonTextarea } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";
import { format, parseISO } from "date-fns";

export default {
  components: {
    IonInput,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {},
      activity: {
        datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      },
      requiredString: yup.string().required("This is required"),
      requiredNumber: yup.number().required("This is required"),
      now: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    };
  },

  computed: {
    activityTitle() {
      if (this.activity.type === "sitz_bath")
        return "Edit Sitz Bath";
      else if (this.activity.type === "foot_bath")
        return "Edit Foot Bath";
      else if (this.activity.type === "breast_massage")
        return "Edit Breast Massage";
      else if (this.activity.type === "lactation_assistance")
        return "Edit Lactation Assistance";
      else if (this.activity.type === "pump_delivered")
        return "Edit Delivered Breast Pump";
      else if (this.activity.type === "pump_retrieved")
        return "Edit Retreived Breast Pump";
      else if (this.activity.type === "baby_in_guest_room")
        return "Edit Baby in Room";
      else if (this.activity.type === "baby_in_nursery")
        return "Edit Baby in Nursery";
      else if (this.activity.type === "baby_feeding")
        return "Edit Baby Feeding";
      else if (this.activity.type === "breastmilk_received")
        return "Edit Collected Breastmilk";
      else if (this.activity.type === "baby_bath")
        return "Edit Bath";
      else if (this.activity.type === "baby_diaper_wet")
        return "Edit Diaper (Wet)";
      else if (this.activity.type === "baby_diaper_poop")
        return "Edit Diaper (Poop)";
      else if (this.activity.type === "baby_diaper_empty")
        return "Edit Diaper (Empty)";
      else if (this.activity.type === "sales_info_session_zoom")
        return "Info Session (Zoom)";
      else if (this.activity.type === "sales_ca_call_zoom")
        return "1:1 CA Call (Zoom or Phone)";
      else if (this.activity.type === "sales_tour_onsite")
        return "Tour (Onsite)";
      else return "Edit Activity";
    },

    showOption1() {
      if (this.activity.type === "baby_feeding")
        return true
      return false
    },

    showMeasurement1() {
      if (this.activity.type === "baby_feeding" || this.activity.type === "breastmilk_received")
        return true
      return false
    },

    showUnits1() {
      if (this.activity.type === "baby_feeding" || this.activity.type === "breastmilk_received")
        return true
      return false
    },

    canEdit() {
      if (this.activity.type === "baby_feeding" ||
        this.activity.type === "breastmilk_received" ||
        this.activity.type === "baby_diaper_wet" ||
        this.activity.type === "baby_diaper_poop" ||
        this.activity.type === "baby_diaper_empty" ||
        this.activity.type === "baby_diaper_mixed" ||
        this.activity.type === "sales_info_session_zoom" ||
        this.activity.type === "sales_ca_call_zoom" ||
        this.activity.type === "sales_tour_onsite")
        return true
      return false
    }
  },

  async ionViewWillEnter() {
    await this.fetchUser();
    await this.fetchActivity()

    // Set defaults if Feeding
    if (this.activity.type === "baby_feeding") {
      this.activity.units_1 = "ml";
    }
    else if (this.activity.type === "breastmilk_received") {
      this.activity.option_1 = "breastmilk";
      this.activity.units_1 = "ml";
    }
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    /**
     * Fetch User
     */
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => { console.log(error) });
    },

    /**
     * Fetch Activity
     */
    async fetchActivity() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/activities/${this.$route.params.activity_id}`)
        .then((response) => {
          this.activity = response.data.activity;

          this.activity.datetime = format(parseISO(response.data.activity.datetime), "yyyy-MM-dd'T'hh:mm")
        })
        .catch((error) => { console.log(error) });
    },

    /**
     * Update Activity
     */
    async updateActivity() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/activities/${this.activity.id}`,
        data: { ...this.activity },
      };

      // Fix  Date
      if (config.data.datetime)
        config.data.datetime = new Date(config.data.datetime)
          .toISOString()
          .replace("T", " ")
          .replace("Z", "");

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then(() => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Success",
            color: "secondary",
          });

          this.$router.back();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>